<template>
  <footer
    class="footer"
    :style="{
      background: style.footerBackgroundColor,
      color: style.footerTextColor
    }"
  >
    <cookie-law
      :button-text="$t('general.ok')"
      :button-link-text="$t('footer.privacyPolicy')"
      button-link="/privacy-policy"
      :button-link-new-tab="true"
    >
      <div slot="message">{{ 'notifications.cookieMessage' | translate }}</div>
    </cookie-law>
    <div class="row">
      <div class="col-6">Talmundo © {{ currentYear }}</div>
      <div class="col-6 text-right">
        <router-link
          class="footer-link"
          v-if="!company.privacyDisclaimerHTML"
          :style="{ color: style.footerTextColor + '!important' }"
          to="/privacy-policy"
          target="_blank"
          >{{ 'footer.privacyPolicy' | translate }}</router-link
        >
        <span class="footer-link pointer" v-if="company.privacyDisclaimerHTML" @click="showPrivacyPolicy">{{
          'footer.privacyPolicy' | translate
        }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

import { mapGetters } from 'vuex';

export default {
  components: {
    CookieLaw
  },
  computed: {
    ...mapGetters('settings', ['company', 'style'])
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    showPrivacyPolicy() {
      this.$alert(this.company.privacyDisclaimerHTML, {
        showConfirmButton: false,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true,
        closeOnPressEscape: true,
        customClass: 'large'
      });
    }
  }
};
</script>

<style style="scss" scoped>
footer {
  position: fixed;
  height: 30px;
  background: white;
  font-size: smaller;
  bottom: 0;
  padding: 8px 24px;
  z-index: 101;
  width: 100%;
  box-shadow: -1px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
